function moveBackground(x) {
  let y = window.innerHeight + window.innerHeight / 5;

  document.body.style.background = `radial-gradient(ellipse at ${x}px ${y}px, rgba(84, 54, 82, 1) 0%, rgba(29, 29, 29, 1) 60%)`;
}

document.body.addEventListener('mousemove', function (e) {
  moveBackground(e.pageX);
});

document.body.addEventListener('touchmove', function (e) {
  moveBackground(e.changedTouches[0].pageX);
});

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
